import { CryptoCurrency } from '@/app/types/app/crypto'

export enum EmailApi {
  Resend = '/api/email/resend',
}

export enum AuthApi {
  Login = '/api/auth/login',
  Logout = '/api/auth/logout',
  Signup = '/api/auth/register',
  ForgotPassword = '/api/auth/password/email',
  ResetPassword = '/api/auth/password/reset',
  CheckResetToken = '/api/auth/password/check-token',
}

export enum UsersApi {
  FetchUser = '/api/users/me',
}

export interface User {
  id: number
  email: string
  username: string
  email_verified_at: string | null
  analytics_id: string
}

export interface Balance {
  amount: number
  pendingReceived: number
  fiatAmount: number
  currency: CryptoCurrency
}

export interface UserResponse {
  user: User
  balances: Balance[]
}

export enum SignupType {
  Paygate = 'payment_gateway',
  Auth = 'auth',
}

export interface AuthFormData {
  email: string
  password: string
}

export interface SignupFormData extends AuthFormData {
  username: string
  password_confirmation?: string
  registration_type: SignupType
  referral_token?: string
}

export interface AuthResponse {
  access_token: string
  user: User
}

export interface RecoveryData {
  token: string
  email: string
  password: string
  password_confirmation: string
}
