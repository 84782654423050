import axiosConfig from '@/app/api/factories/axiosConfig'
import AxiosFactory from '@/app/api/factories/AxiosFactory'

export default AxiosFactory.create(
  axiosConfig.baseURL,
  axiosConfig.headers,
  axiosConfig.timeout,
  axiosConfig.requestInterceptors,
  axiosConfig.responseInterceptor,
)
