export enum RoutePath {
  Login = '/login',
  Signup = '/signup',
  EmailVerify = '/email/verify',
  EmailVerifyFailed = '/email/verify/failed',
  EmailVerifyToken = '/email/verify/:id/:token',
  PasswordRecovery = '/password/recovery',
  PasswordRecoveryNew = '/password/new',
  PasswordRecoverySent = '/password/sent',
  PasswordRecoverySuccess = '/password/success',
  PasswordRecoveryFailed = '/password/failed',
  PasswordReset = '/password/reset',
}

export enum RouteName {
  Login = 'login',
  Signup = 'signup',
  EmailVerify = 'verify',
  EmailVerifyFailed = 'verify-failed',
  EmailVerifyToken = 'verify-process',
  PasswordRecovery = 'password-recovery',
  PasswordRecoveryNew = 'password-recovery-new',
  PasswordRecoverySent = 'password-recovery-sent',
  PasswordRecoverySuccess = 'password-recovery-success',
  PasswordRecoveryFailed = 'password-recovery-failed',
  PasswordReset = 'password-reset',
  Maintenance = 'maintenance',
}
