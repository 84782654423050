import { RouteRecordRaw } from 'vue-router'

import { RouteName, RoutePath } from '@/app/types/app/routes'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: RoutePath.Login,
  },
  {
    path: '/',
    component: () => import('@/layouts/DefaultLayout.vue'),
    children: [
      {
        path: RoutePath.Login,
        name: RouteName.Login,
        component: () => import('@/views/login.vue'),
      },
      {
        path: RoutePath.Signup,
        name: RouteName.Signup,
        component: () => import('@/views/signup.vue'),
      },
      {
        path: RoutePath.EmailVerify,
        name: RouteName.EmailVerify,
        component: () => import('@/views/email/index.vue'),
        meta: { canLogout: true },
      },
      {
        path: RoutePath.EmailVerifyFailed,
        name: RouteName.EmailVerifyFailed,
        component: () => import('@/views/email/index.vue'),
        meta: { canLogout: true },
      },
      {
        path: RoutePath.EmailVerifyToken,
        name: RouteName.EmailVerifyToken,
        component: () => import('@/views/email/verify/_id/_token.vue'),
      },
      {
        path: RoutePath.PasswordRecovery,
        name: RouteName.PasswordRecovery,
        component: () => import('@/views/password/index.vue'),
      },
      {
        path: RoutePath.PasswordRecoveryNew,
        name: RouteName.PasswordRecoveryNew,
        component: () => import('@/views/password/index.vue'),
      },
      {
        path: RoutePath.PasswordRecoverySent,
        name: RouteName.PasswordRecoverySent,
        component: () => import('@/views/password/status.vue'),
      },
      {
        path: RoutePath.PasswordRecoverySuccess,
        name: RouteName.PasswordRecoverySuccess,
        component: () => import('@/views/password/status.vue'),
      },
      {
        path: RoutePath.PasswordRecoveryFailed,
        name: RouteName.PasswordRecoveryFailed,
        component: () => import('@/views/password/status.vue'),
      },
      {
        path: RoutePath.PasswordReset,
        name: RouteName.PasswordReset,
        component: () => import('@/views/password/reset.vue'),
      },
      {
        name: RouteName.Maintenance,
        path: '/maintenance',
        component: () => import('@/views/ServiceViews/ViewMaintenance.vue'),
      },
    ],
  },
  // 404
  {
    path: '/:catchAll(.*)',
    name: 'not-found',
    redirect: RoutePath.Login,
  },
]

export default routes
