import '@/assets/scss/ui/quasar.css'
import '@quasar/extras/material-icons/material-icons.css'
import './assets/css/tailwind.css'
import '@/assets/scss/ui/notify.scss'
import '@/assets/scss/ui/icons.scss'
import './assets/css/variables.css'
import './assets/scss/entry.scss'

import Quasar from 'quasar/src/vue-plugin.js';
import { createApp } from 'vue'
import { createMetaManager } from 'vue-meta'

import App from './App.vue'
import quasarUserOptions from './app/quasar/quasar-user-options'
import i18n from './plugins/i18n'
import router from './router'
import store from './store'

createApp(App)
  .use(Quasar, quasarUserOptions)
  .use(store)
  .use(router)
  .use(i18n)
  .use(createMetaManager())
  .mount('#app')
