import { AxiosError, InternalAxiosRequestConfig } from 'axios'

import {
  ErrorResponse,
  HttpStatus,
  RequestHeaders,
} from '@/app/types/api/config'
import { GtmEvent } from '@/app/types/app/event'
import { RouteName } from '@/app/types/app/routes'
import { StoreCommit } from '@/app/types/store/store'

import cookies from '@/plugins/cookies'
import i18n from '@/plugins/i18n'
import Store from '@/store'
import getUniqId from '@/utils/getUniqId'

import env from '@/app/env'

import router from '@/router'

enum ServerErrors {
  Standard = 'An error occurred. Refresh page and try again',
}

const AppProjectName = 'INXY-AUTH'

// getToken

export function getToken() {
  const token = cookies.get(env.cookieName)

  return token ? 'Bearer ' + token : ''
}

// setRequestHeaders

export function setRequestHeaders(config: InternalAxiosRequestConfig) {
  // set auth header
  const noToken = !config?.headers?.Authorization

  if (config.headers && noToken) {
    config.headers['Authorization'] = getToken()
  }

  // set path and page-id headers
  const path = router.currentRoute.value.path

  if (config.headers && path) {
    config.headers[RequestHeaders.PageRequestId] = getUniqId(path)
    config.headers[RequestHeaders.PagePath] = path
    config.headers[RequestHeaders.ProjectName] = AppProjectName
  }

  return config
}

// getErrorMessages

export function getErrorMessages(error: AxiosError<ErrorResponse>): string[] {
  const message = error.response?.data?.message ?? ServerErrors.Standard
  const errors = error.response?.data?.errors

  switch (error?.response?.status) {
    case HttpStatus.Unauthorized:
      cookies.remove(env.cookieName, '/', env.domain)
      window.dataLayer?.push({ event: GtmEvent.Logout })
      router.push({ name: RouteName.Login })

      return [message]
    case HttpStatus.UnprocessableEntity:
      return errors ? Object.values(errors).flat() : [message]
    case HttpStatus.ServiceUnavailable:
      return [i18n.global.t('errors.serviceUnavailable')]
    default:
      return [message]
  }
}

// setMaintenanceStatus

export function setMaintenanceStatus(responseStatus: HttpStatus | undefined) {
  if (responseStatus === HttpStatus.ServiceUnavailable) {
    Store.commit(StoreCommit.ChangeServerStatus, responseStatus)
  }
}
