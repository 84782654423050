import { AxiosError } from 'axios'
import Notify from 'quasar/src/plugins/Notify.js';
import { QNotifyCreateOptions } from 'quasar/dist/types/index'

import { ErrorResponse, HttpStatus } from '@/app/types/api/config'
import { ServiceEndpoint } from '@/app/types/api/resources/service'
import { Translation } from '@/app/types/app/translation/translation'

import Store from '@/store'

export enum InterceptorNotifyName {
  Maintenance = 'maintenance',
}

export default class ErrorResponseInterceptorNotify {
  readonly #error: AxiosError<ErrorResponse>
  readonly #messages: string[]
  readonly #t: Translation

  constructor(
    t: Translation,
    error: AxiosError<ErrorResponse>,
    messages: string[],
  ) {
    this.#error = error
    this.#messages = messages
    this.#t = t
  }

  get #mainErrorMessage(): string {
    return this.#messages[0]
  }

  get #responseStatus(): HttpStatus | undefined {
    return this.#error.response?.status
  }

  get notifyName(): InterceptorNotifyName | null {
    if (Store.getters['service/isServerMaintaining']) {
      return InterceptorNotifyName.Maintenance
    }

    return null
  }

  get options(): QNotifyCreateOptions | null {
    return this.notifyName === null
      ? {
          message: this.#mainErrorMessage,
          type: 'negative',
        }
      : null
  }

  #maintenance() {
    if (this.#error?.config?.url) {
      Notify.create({
        message: this.#t('errors.serviceUnavailable'),
        type: 'negative',
      })
    }
  }

  create() {
    switch (this.notifyName) {
      case InterceptorNotifyName.Maintenance:
        if (this.#error?.config?.url !== ServiceEndpoint.ServerStatus) {
          this.#maintenance()
        }
        break
    }
  }
}
