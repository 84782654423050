import { createI18n } from 'vue-i18n'

import en from '@/lang/en.json'

export default createI18n({
  locale: 'en',
  allowComposition: true,
  messages: { en },
  legacy: false,
})
