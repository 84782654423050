export enum ModelEvent {
  UpdateModelValue = 'update:modelValue',
}

export enum NativeEvent {
  Blur = 'blur',
}

export enum GtmEvent {
  Logout = 'logout',
  Login = 'login',
  Signup = 'registration',
}
