import { AxiosResponse } from 'axios'

import {
  AuthFormData,
  AuthResponse,
  SignupFormData,
  UserResponse,
} from '@/app/types/api/resources/auth'
import { GtmEvent } from '@/app/types/app/event'
import { StoreCommit } from '@/app/types/store/store'

import cookies from '@/plugins/cookies'
import Store from '@/store'

import authApi from '@/app/api/resources/auth'
import env from '@/app/env'

export default class {
  fetchUser() {
    return authApi
      .fetchUser()
      .then(({ data }: AxiosResponse<UserResponse>) =>
        Store.commit(StoreCommit.SetUser, data.user),
      )
  }

  #setAuthToken(data: AuthResponse) {
    Store.commit(StoreCommit.SetUser, data.user)

    cookies.set(env.cookieName, data.access_token, 60 * 60, '/', env.domain)
  }

  login(data: AuthFormData) {
    return authApi.login(data).then(({ data }: AxiosResponse<AuthResponse>) => {
      this.#setAuthToken(data)
      window.dataLayer?.push({
        event: GtmEvent.Login,
        analytics_id: data.user.analytics_id,
      })

      return data
    })
  }

  signup(data: SignupFormData) {
    return authApi
      .signup(data)
      .then(({ data }: AxiosResponse<AuthResponse>) => {
        this.#setAuthToken(data)
        window.dataLayer?.push({
          event: GtmEvent.Signup,
          analytics_id: data.user.analytics_id,
        })
      })
  }

  logout() {
    return authApi.logout().then(() => {
      window.dataLayer?.push({ event: GtmEvent.Logout })

      cookies.remove(env.cookieName, '/', env.domain)

      return Promise.resolve()
    })
  }
}
