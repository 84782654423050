import { AxiosError } from 'axios'

import {
  ErrorResponse,
  HttpStatus,
  RequestInterceptor,
  ResponseInterceptor,
} from '@/app/types/api/config'

import i18n from '@/plugins/i18n'

import ErrorResponseInterceptorNotify from '@/app/api/factories/ErrorResponseInterceptorNotify'
import {
  getErrorMessages,
  getToken,
  setMaintenanceStatus,
  setRequestHeaders,
} from '@/app/api/factories/utils'
import ClientResponseError from '@/app/ClientResponseError'
import env from '@/app/env'

const requestInterceptors: RequestInterceptor = {
  onSuccess(config) {
    return setRequestHeaders(config)
  },
  onError(error) {
    return Promise.reject(error)
  },
}

const responseInterceptor: ResponseInterceptor = {
  onSuccess(response) {
    return response
  },
  onError(error: AxiosError<ErrorResponse>): Promise<ClientResponseError> {
    const responseStatus: HttpStatus | undefined = error.response?.status

    setMaintenanceStatus(responseStatus)

    const messages = getErrorMessages(error)

    const notify = new ErrorResponseInterceptorNotify(
      i18n.global.t,
      error,
      messages,
    )

    notify.create()

    return Promise.reject(
      new ClientResponseError({
        name: error.name,
        message: error.message,
        status: responseStatus,
        errors: error.response?.data?.errors ?? null,
        messages,
        notify: notify.options,
      }),
    )
  },
}

export default {
  baseURL: env.baseURL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: getToken(),
  },
  timeout: +env.axiosTimeout,
  requestInterceptors,
  responseInterceptor,
}
