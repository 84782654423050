import { HttpStatus } from '@/app/types/api/config'
import { RouteName } from '@/app/types/app/routes'

import ClientResponseError from '@/app/ClientResponseError'

import router from '@/router'

export function resolveRoute(name: RouteName) {
  return router.resolve({ name })
}

export function resolveVerifiedUserRoute(toRouteName: RouteName) {
  const toRouteMaintenance = toRouteName === RouteName.Maintenance

  if (toRouteMaintenance) {
    return resolveRoute(RouteName.Login)
  }

  return
}

export function resolveServicePageRoute(
  error: Error | ClientResponseError,
  toRouteName: RouteName,
) {
  if (error instanceof ClientResponseError) {
    switch (error.status) {
      case HttpStatus.ServiceUnavailable:
        if (toRouteName === RouteName.Maintenance) {
          return
        }

        return resolveRoute(RouteName.Maintenance)
      default:
        return
    }
  }

  return
}
