import Notify from 'quasar/src/plugins/Notify.js';import Screen from 'quasar/src/plugins/Screen.js';

export default {
  config: {
    brand: {
      negative: 'var(--clr-error)',
      positive: 'var(--clr-success)',
      warning: 'var(--clr-warning)',
    },
    notify: {
      position: 'top',
      classes: 'app-notify',
    },
  },
  plugins: {
    Notify,
    Screen,
  },
}
